<template>
  <div>
    <!-- <Element-view></Element-view> -->
     <!-- 员工管理组件 -->
     <!-- <emp-view></emp-view> -->
      <router-view></router-view>

  </div>
</template>

<script>
// import ElementView from './views/element/ElementView.vue'
// import EmpView from './views/tlias/EmpView.vue'
export default {
  components: { /*ElementView, EmpView*/ }
}
</script>

<style></style>
